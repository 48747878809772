body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fff; /* Page body color */
    color: #5b5959; /* Text color */
  }
  
  .navbar {
    background-color: #d92525; /* Primary color */
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  
  .logo img {
    max-width: 100px;
  }
  
  .nav-links a {
    color: #fff;
    text-decoration: none;
    margin-left: 20px;
  }
  
  .nav-links a:hover {
    text-decoration: underline;
  }
  
  header {
    text-align: center;
    padding: 50px 0;
    background-color: #fff;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    padding: 50px 0;
  }
  
  .feature {
    text-align: center;
    flex-basis: 30%;
  }
  
  .feature img {
    max-width: 100%;
    height: auto;
  }
  
  footer {
    text-align: center;
    padding: 20px 0;
    background-color: #333;
    color: #fff;
  }
  