body {
  background-color: #fff;
}
.main-container {
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
}
.main-container a {
  text-decoration: none;
}
.main-container .login-wrapper {

  -webkit-box-shadow: 0px 0px 100px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 100px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 100px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 980px;
  min-height: 600px;
  display: flex;
  z-index: 9;
}
.main-container .login-wrapper .form-wrapper {
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.main-container .login-wrapper .form-wrapper .form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  margin-top: 15px;
  font-weight: 900;
}

.main-container .login-wrapper .form-wrapper .form-header .fa-arrow-left,
.main-container .login-wrapper .form-wrapper .form-header .register-link,
.main-container .login-wrapper .form-wrapper .form-container .forgot-pass {
  color: #1097ff;
}
.main-container .login-wrapper .form-wrapper .form-container {
  padding: 0px 45px;
}

.main-container .login-wrapper .form-wrapper .form-container .form-messages {
  text-align: left;
  line-height: 20px;
  margin-bottom: 30px;
}

.main-container .login-wrapper .form-wrapper .form-container .form-messages h2 {
  margin-bottom: 15px;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
}

.main-container .login-wrapper .form-wrapper .form-container .form input {
  width: 100%;
  margin-bottom: 7px;
  background-color: #ffffff;
  border: none;
  padding: 10px;
  border: 1px solid #e0e0e0;
}
.main-container .login-wrapper .form-wrapper .form-container .form label,
.control-label {
  font-size: 17px;
  margin-bottom: 5px;
  color: #333333;
  font-weight: 500;
  margin-right: 7px;
}
.main-container .login-wrapper .form-wrapper .form-container .form input:focus {
  outline: 2px solid #0f75b9;
}

.main-container .login-wrapper .form-wrapper .form-container .form a {
  display: inline-block;
  color: #1097ff;
  font-weight: 500;
}

.main-container
  .login-wrapper
  .form-wrapper
  .form-container
  .form
  .forgot-pass {
  text-align: right;
  font-weight: 500;
  display: block;
}
.login-warn-text {
  font-size: 18px;
  text-align: justify;
  font-weight: 500;
  color: #333333;
  font-weight: 500;
  margin-bottom: 21px;
}
.main-container
  .login-wrapper
  .form-wrapper
  .form-container
  .form
  .login-button,
.login-button {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 2px;
  background-color: #D92525;
  color: #fff;
  text-align: center;
  display: block;
  font-size: 15px;
  width: 100%;
  border: 1px solid #D92525;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  cursor: pointer;
}

.modal-footer.cutum-footer {
  padding: 10px 0px 0px 0px !important;
}
.modal-header-profile p {
}
.modal-header-login h4 {
  /* font-size: 22px;
  line-height: 30px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif; */
}
button.btn.btn-blue.w-100.mt-4 {
  background-color: #106bdc !important;
  min-height: 42px;
  border-radius: 0 !important;
}

/* Separator */
.main-container .login-wrapper .form-wrapper .form-container .separator {
  border: 1px solid #e8e9ea;
  margin-bottom: 35px;
}
.main-container .forgot-block .image-wrapper {
  /*   background: url("../images/forgot-bg.png"); */
  background-size: cover;
  background-position: right;
}
.main-container .register-block .image-wrapper {
  /*  background: url("../images/register-bg.png"); */
  background-size: cover;
  background-position: right;
}
.main-container .image-wrapper {
  background-color: #d92525;
  background-position: right;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 600px;
}

.main-container .image-wrapper .image-message {
  color: #fff;
  line-height: 20px;
  padding: 50px 30px 60px 30px;
  display: block;
  flex-direction: row;
  text-align: center;
}
.main-container .image-wrapper .image-message .image-hr-container {
  margin: 5px 20px;
}
.main-container .image-wrapper .image-message .image-hr-container .image-hr {
  border: 1px solid #D92525;
  max-width: 70px;
  margin-bottom: 30px;
}

.main-container .image-wrapper .image-message .image-text h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}
.quad-green-bg {
  width: 34%;
  position: absolute;
  height: 100%;
  display: block;
  background-color: #121635;
  left: 0;
}
.login-logo {
  width: 120px;
  margin-bottom: 50px;
}
.image-message h2 {
  font-size: 24px;
}
.social-icons {
  display: flex;
  list-style: none;
  justify-content: center;
  padding-left: 0;
  margin: 30px 0 15px;
}
.social-icons li {
  background-color: #fff;
  width: 32px;
  height: 32px;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.social-icons li:hover {
  transform: rotate(360deg);
  background-color: #D92525;
}
.social-icons li i.fa {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  font-size: 18px;
}
.login-text {
  text-align: center;
  font-size: 14px;
  color: #888;
  display: inline-block;
  width: 100%;
}
.login-check {
  float: left;
  margin-bottom: 20px;
}
.login-check input {
  width: auto !important;
  position: relative;
  top: 2px;
}
.login-check span {
  color: #888;
}
.login-text a {
  color: #1097ff;
  font-weight: 500;
}
.pos-rel {
  position: relative;
}
.pos-rel .fa-eye,
.pos-rel .fa-eye-slash {
  top: 42px;
  right: 10px;
  font-size: 16px;
  color: #555;
  padding: 2px 11px;
  background-color: #fff;
  position: absolute;
}
.no-padding {
  padding: 0px;
}
.base-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 590px;
}
.ant-form-item {
  margin-bottom: 0px !important;
}
.ant-form-item-explain-connected {
  font: size 12px;
  color: red;
}
.ant-btn-primary {
  background-color: #D92525 !important;
  border-color: #D92525 !important;
}
.ant-layout-sider-light .ant-layout-sider-trigger{
  background-color: #D92525 !important;
  border-color: #D92525 !important;
  color: white !important;
}
.ms-Button--compound{
  background-color: #D92525 !important;
  border-color: #D92525 !important;
  color: white !important;
}
.txt{
  color: #191B40;
}
